<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialog" max-width="575" scrollable>
			<v-card>
				<v-card-title class="pr-10">Confirmar emissão de seguro</v-card-title>
				<v-divider />
				<v-card-text class="black--text pt-5">
					<v-row no-gutters>
						<v-spacer />
						<v-col cols="auto">
							<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
						</v-col>
						<v-spacer />
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialog = false"
					>Cancelar</v-btn>
					<v-btn :loading="carregando" color="primary" elevation="0" @click="enviarSeguro()">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" md="8">
			<v-card class="mx-auto">
				<v-card-title>Selecine o título</v-card-title>
				<v-divider />
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th></th>
								<th>Filial</th>
								<th>Título</th>
								<th>Série</th>
								<th>Valor do título</th>
								<th>Valor juros</th>
								<th>Valor total</th>
								<th>Data</th>
								<th>Forma de pagamento</th>
							</tr>
						</thead>
						<tbody v-if="titulos.total > 0">
							<tr v-for="(u,i) in titulos.lista" :key="i">
								<td>
									<v-radio-group v-model="bilhete.idpedido">
										<v-radio :value="u" />
									</v-radio-group>
								</td>
								<td>{{u.idfilial}}</td>
								<td>{{u.titulo}}</td>
								<td>{{u.serie}}</td>
								<td>{{u.saldotitulo | formataDinheiro}}</td>
								<td>{{u.jurostitulo | formataDinheiro}}</td>
								<td>{{u.valorliqtitulo | formataDinheiro}}</td>
								<td>{{u.dtmovimento}}</td>
								<td>{{u.idrecebimento}} - {{u.recebimento}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td class="text-center" colspan="9">Nenhum título encontrado.</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="4">
			<v-card class="mx-auto">
				<v-card-title>Dados do seguro</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row class="mb-3">
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Cliente</div>
							<v-autocomplete
								no-filter
								v-model="bilhete.idcliente"
								outlined
								dense
								:search-input.sync="searchCliente"
								:items="itemsCliente"
								item-text="nome"
								item-value="idcliente"
								class="mb-n10"
								@change="listar()"
							></v-autocomplete>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Vendedor</div>
							<v-autocomplete
								no-filter
								v-model="bilhete.idvendedor"
								outlined
								dense
								:search-input.sync="searchVendedor"
								:items="itemsVendedor"
								item-text="nome"
								item-value="idcliente"
								class="mb-n10"
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-btn block color="primary" elevation="0" @click="abrirDialog()">Enviar</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import jsPDF from "jspdf";
import CardDialog from "../Widgets/CardDialog.vue";
export default {
	components: { CardDialog },
	name: "SeguroPrestamista",
	data: () => ({
		carregando: false,
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		titulos: { total: 0 },
		bilhete: { idpedido: 0, idcliente: 0, idvendedor: 0 },
		dados: {},
		aguardarBusca: "",
		nomeLimit: 50,
		clientes: [],
		vendedores: [],
		searchCliente: null,
		searchVendedor: null,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
		itemsCliente() {
			if (this.clientes == null) return;

			this.clientes.forEach((cliente) => {
				if (cliente.nome.length > this.nomeLimit) {
					cliente.nome =
						cliente.nome.slice(0, this.nomeLimit) + "...";
				}
			});

			return this.clientes;
		},
		itemsVendedor() {
			if (this.vendedores == null) return;

			this.vendedores.forEach((vendedor) => {
				if (vendedor.nome.length > this.nomeLimit) {
					vendedor.nome =
						vendedor.nome.slice(0, this.nomeLimit) + "...";
				}
			});

			return this.vendedores;
		},
	},
	methods: {
		listar() {
			if (
				!this.bilhete.idcliente ||
				this.bilhete.idcliente == 0 ||
				this.bilhete.idcliente == null
			) {
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}seguro/listar/prestamista`, {
					limit: 30,
					offset: 0,
					idcliente: this.bilhete.idcliente,
				})
				.then((res) => {
					this.titulos = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		abrirDialog() {
			if (this.bilhete.idpedido == 0) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Obrigatório informar o título antes de gerar o seguro!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.bilhete.idcliente == 0) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Obrigatório informar o cliente antes de gerar o seguro!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.bilhete.idvendedor == 0) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Obrigatório informar o vendedor antes de gerar o seguro!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.dialog = true;
		},
		enviarSeguro() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}seguro/faturar/prestamista`, {
					idpedido: parseInt(this.bilhete.idpedido),
					idcliente: parseInt(this.bilhete.idcliente),
					idvendedor: parseInt(this.bilhete.idvendedor),
				})
				.then((res) => {
					this.bilhete = {
						idcliente: 0,
						idpedido: 0,
						idvendedor: 0,
					};
					this.dialog = false;
					this.carregando = false;
					this.dados = res.data;
					let htmlContent;
					htmlContent = `html`;
					let doc = new jsPDF("p", "pt", "a4");
					let dataSeguro = new Date();
					doc.html(htmlContent, {
						callback: function (pdf) {
							pdf.save(
								`seguro_prestamista_${
									dataSeguro.toISOString().split(".")[0]
								}.pdf`
							);
						},
					});
					doc.autoPrint();
				})
				.catch((e) => {
					this.dialog = false;
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						this.erroFormatar(e) ||
						"Não foi possível realizar o envio do contrato!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		carregarClientes(val) {
			clearTimeout(this.aguardarBusca);

			this.aguardarBusca = setTimeout(() => {
				this.carregando = true;
				return axios
					.post(`${this.backendUrl}cliente/listar`, {
						limit: 30,
						offset: 0,
						busca: val || null,
					})
					.then((res) => {
						this.clientes = res.data.lista;
						this.carregando = false;
					})
					.catch(() => {
						this.carregando = false;
					});
			}, this.timeLimit);
		},
		carregarVendedores(val) {
			clearTimeout(this.aguardarBusca);

			this.aguardarBusca = setTimeout(() => {
				this.carregando = true;
				return axios
					.post(`${this.backendUrl}cliente/listar`, {
						limit: 30,
						offset: 0,
						tipo: "'U','R','V'",
						busca: val || null,
					})
					.then((res) => {
						this.vendedores = res.data.lista;
						this.carregando = false;
					})
					.catch(() => {
						this.carregando = false;
					});
			}, this.timeLimit);
		},
	},
	watch: {
		searchCliente: function (val) {
			this.carregarClientes(val);
		},
		searchVendedor: function (val) {
			this.carregarVendedores(val);
		},
	},
};
</script>

<style>
</style>